import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import React, { memo } from 'react'

export interface Props {
  buttons?: string[]
  title?: string
}

export const Media = memo(function Media({ buttons, title }: Props) {
  if (!buttons) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}

      {buttons.map((item: any, index) => (
        <CTA
          key={index}
          icon="download"
          label={item.label}
          target="_blank"
          URL={item.url}
          variant="outline"
          {...item}
        />
      ))}
    </Container>
  )
})

const Container = styled.div`
  max-width: 26.5rem;
  margin: 3.75rem auto 0;
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.75rem;
  margin-bottom: 1.25rem;
  text-transform: uppercase;
`

const CTA = styled(Button)`
  width: 100%;
  justify-content: flex-start;
  margin-top: 0.375rem;
`
